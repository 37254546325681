.loading-bar {
    width: 100%;
    height: 20px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    padding-left: 20%;
    padding-right: 20%;
}

.loading-bar-fill {
    height: 100%;
    width: 0;
    border-radius: 10px;
    animation: loading 3s infinite;
    background: repeating-linear-gradient(
        45deg,
        #4caf50,
        #4caf50 10px,
        #66bb6a 10px,
        #66bb6a 20px);
}

@keyframes loading {
    0% { width: 0; }
    100% { width: 100%; }
}