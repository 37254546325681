.App {
  text-align: center;
}

@media (max-width: 768px) {

  [class*="col-"] {
      margin-bottom: 30px;
  }

  [class*="col-md-3"]{
    position: relative;
    overflow: hidden;
  }


}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.circular-image {
  border-radius: 50%;
  width: 275px;
  height: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cursor {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.causes, .hobbies, .education, .featured, .work, .projects {
  padding-left: 30px;
  padding-right: 30px;
  align-content: center;
  font-family: 'Raleway', sans-serif;
}

.menubar {
  color: #d3d3d3;
  font-family: 'Raleway', sans-serif;
  font-size: x-large;

  a {
    color: #636b6b;
  }
}

.Iam {
  color: #ffffff;
  background-color: #282c34;
  height: 300px;
  padding-top: 50px;
  font-family: 'Raleway', sans-serif;
}

.languages {
  align-content: flex-start;
  padding-right: 10%;
}


#about {
  text-align: center;
  padding-right: 10%;
  font-family: 'Manrope', sans-serif;
  color: midnightblue;

}

#aboutText {
  display: flexbox;
  margin-left: auto;
  margin-right: auto;
}

#causesEd, #causesMH, #causesDI, #hobbiesLang, #otherHobbies, #podcast, #video, #profilesRow, #instaRow {
  text-align: justify;
  padding: 3%;
}

.causeHeader, .articleHeader, .vidHeader, .profileHeader, .instaHeader, .projectsHeader {
  text-align: center;

}

#causesHeader {
  color: maroon;
}

#hobbiesHeader {
  color: #FFC300;
}

#featHeader {
  color: indigo;
}

#projectsHeader {
  color: orange
}

#duol {
  text-align: justify;
  margin-left: 10%;
}

#ted {
  text-align: justify;
  margin-left: 10%;
}

#toastmasters {
  text-align: justify;
  margin-left: 5%;
  margin-right: 5%;
}

#ED {
  color: #140385;
}

#MH {
  color: green;
}

#DI {
  color: purple;
}

#studentBlogs{
  align-content: center;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5%;
}

#pcast {
  align-content: center;
  text-align: center;
}

#vid {
  align-content: center;
  align-self: center;
}

#petproj {
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  font-size: large;
}

.projectsContent {
  align-content: center;
}

.video, .iframe {
  display: none;
}

#videoFrame, .videoCap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.videoCap{
  text-align: center;
}


.contact-form {
   width: 170px;
}

.responsive {
  width: 100%;
  height: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.linkedinIcon {
  color: #FFC300;
}

.xIcon {
  color:aquamarine
}

.githubIcon {
  color: #000000;
}

.msgIcon {
  color: #5d0775;
}

/*
  * Contact Form
*/

.contact-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.contact-form {
  background: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: auto;
  width: 50%;
  overflow-y: auto;
}

.contact-form h2 {
  margin-top: 0;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 10px;
}

.contact-form button {
  margin-top: 10px;
}

.form-submit-button {
  background-color: rgb(119, 191, 119);
  width: 50px;
  width: 75px;
  height: 40px;
  border-radius: 5px;
  font-size: large;
}

.form-close-button {
  background-color: rgb(219, 118, 111);
  width: 75px;
  height: 40px;
  border-radius: 5px;
  font-size: large;
}

.button-container {
  display: flex;
  justify-content: center;
}

label {
  font-size: 1.2em;
  font-weight: bold;
}

textarea {
  border-width: 2px;
}

@keyframes beat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.beat {
  animation: beat 1s infinite;
}

.blink {
  animation: blink 1s ease-in-out infinite alternate;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

